interface ToggleModeConfig {
    element: string,
}

export default class ToggleMode {

    config: ToggleModeConfig = {
        element: '[data-toggle-mode]',
    };
    mode: string = 'light'
    darkMode: boolean = false;

    constructor(config: {} = {}) {
        this.config = {...this.config, ...config};
        this.darkMode = this.localDarkMode();
        this.detectColorScheme();
        this.mode = this.darkMode ? 'dark' : 'light';
        this.getElements().forEach((element: HTMLElement) => this.elementClickEvent(element, (event: Event) => this.clickEvent(event)));
    }

    getElements(): any[] {
        return [...document.querySelectorAll(this.config.element)];
    }

    elementClickEvent(element: HTMLElement, callback): void {
        element.addEventListener('click', (e: Event) => callback(e))
    }

    clickEvent(event: Event): void {
        event.preventDefault();
        this.switchMode();
    }

    isDarkMode(): boolean {
        return window.matchMedia(`(prefers-color-scheme: dark)`).matches;
    }

    localDarkMode(): boolean {
        return localStorage.getItem('mode') === 'dark';
    }

    switchMode(): void {
        this.setMode();
        this.detectColorScheme();
    }

    setMode(): void {
        this.darkMode = !this.darkMode;
        this.mode = this.darkMode ? 'dark' : 'light'
        localStorage.setItem('mode', this.darkMode ? 'dark' : 'light');
    }

    detectColorScheme(): void {
        const elementClass = document.documentElement.classList;
        this.localDarkMode() ? elementClass.add('dark') : elementClass.remove('dark');
    }
}
